import React, { useState } from 'react'
import { Drawer, Modal, Dropdown, Button, Space } from 'antd'
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer'
import Preview from '../../../node_modules/@sidexia/visionformpreview/lib/lib/components/Preview/Preview'
import type { MenuProps } from 'antd'
import WidgetModal from '../widgetsComponent/WidgetModal'

interface Props {
  index: any
  showCard: any
  item: any
  setCurrentParentData: any
  showData: any
  refresh: any
}
const WidgetData: React.FC<Props> = ({
  index,
  showCard,
  item,
  setCurrentParentData,
  showData,
  refresh,
}) => {
  const [fileUrl, setFileUrl] = useState('')
  const [showDrawer, setShowDrawer] = useState(false)
  const [data, setData] = useState<any>()
  const [widgetSettings, setWidgetSettings] = useState<any>()
  const [produitId, setProduitId] = useState<any>()
  const [widgetId, setWidgetId] = useState<any>()
  const [open, setOpen] = useState(false)
  const [currentId, setCurrentId] = useState<any>()
  const [widgets, setWidgets] = useState<any>([])
  const [openForm, setOpenForm] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleOpen = (
    settings: any,
    widget_id: number,
    produit_id: number,
    data_id: any,
    data: any
  ) => {
    setWidgetSettings(
      typeof settings === 'string' ? JSON.parse(settings) : settings
    )
    setProduitId(produit_id)
    setWidgetId(widget_id)
    setOpen(true)
    setCurrentId(data_id)
    setData(data)
  }
  const onClose = () => {
    setShowDrawer(false)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const optionButton = (item: any) => {
    const menuItems = { items: [] as any } as MenuProps
    const dropdownElement = [
      {
        key: 0,
        label: (
          <>
            <span className="fa fa-pen me-3" />
            Edit
          </>
        ),
        onClick: () => {
          handleOpen(
            item?.settings,
            item?.widget_id,
            item?.produit_id,
            item?.data_id,
            item?.valeur
          )
          // setOpenForm(true)
        },
      },
      {
        key: 1,
        label: (
          <>
            <span className="fa fa-archive me-3" />
            Archiver
          </>
        ),
        onClick: () => {
          confirm(item)
        },
      },
    ]
    menuItems.items = dropdownElement
    return menuItems
  }
  const showCards = (item: any) => {
    item.form_preview =
      typeof item.form_preview === 'string'
        ? JSON.parse(item.form_preview)
        : item.form_preview
    if (item.form_preview) {
      return <Preview initElements={{ fields: item.form_preview }} />
    } else {
      return item.values.map((value: any, i: any) => {
        if (value.showInCard) {
          if (item.isRelated) {
            return (
              <div className="row cursor-pointer" key={i}>
                <div className="col mb-3">
                  {value.label.replace(/<[^>]*>?/gm, '')} :{' '}
                </div>
                <div className="col mb-3">{value.text}</div>
              </div>
            )
          } else {
            if (value.type === 'FileUpload') {
              return (
                <div className="row" key={i}>
                  <div className="col mb-3">
                    <strong>{value.label.replace(/<[^>]*>?/gm, '')} : </strong>
                  </div>
                  <div className="col mb-3">{value.text}</div>
                  <button
                    className="ant-btn ant-btn-default"
                    onClick={() => {
                      setFileUrl(
                        `${process.env.REACT_APP_STATIC_FILES}${value.text}`
                      )
                      setShowDrawer(true)
                    }}
                  >
                    <span className="fa fa-eye" /> Preview
                  </button>
                </div>
              )
            } else {
              return (
                <div className="row" key={i}>
                  <div className="col mb-3">
                    <strong>{value.label.replace(/<[^>]*>?/gm, '')} : </strong>
                  </div>
                  <div className="col mb-3">{value.text}</div>
                </div>
              )
            }
          }
        }
      })
    }
  }
  return (
    <>
      <Space size="middle">
        <Button
          className="btn btn-primary rounded-pill mx-2"
          onClick={() => {
            setIsModalOpen(true)
          }}
        >
          <span className="fa fa-eye" />
        </Button>
      </Space>
      <Modal
        title=""
        open={isModalOpen}
        footer={false}
        onCancel={() => {
          setIsModalOpen(false)
        }}
      >
        <div
          key={index}
          className={`${showCard ? 'ant-col ant-col-5 card' : ''}`}
          style={{ margin: `${showCard ? '0 20px' : '0 0 50px 0'}` }}
        >
          <div className="d-flex align-items-center justify-content-between mb-5">
            <h2 className="m-0">{item.nom}</h2>

            <Dropdown placement="bottomRight" menu={optionButton(item)}>
              <button className="ant-btn ant-btn-sm ant-btn-transparent">
                <span className="fa fa-ellipsis-vertical" />
              </button>
            </Dropdown>
          </div>

          <div>{showCards(item)}</div>
          {item.isRelated && (
            <Button
              className="btn btn-primary rounded-pill mx-2"
              onClick={async () => {
                item.id = item.data_id
                setCurrentParentData(item.id)
                await showData(item, item.produit_id)
                setIsModalOpen(false)
              }}
            >
              <span className="fa fa-arrow-right" />
            </Button>
          )}
        </div>
      </Modal>
      {data && (
        <div>
          <WidgetModal
            settings={widgetSettings}
            dataId={currentId}
            prodId={produitId}
            widgetId={widgetId}
            open={open}
            handleClose={handleClose}
            isEdit={true}
            data={data}
            isDefault={false}
            setWidget={setWidgets}
            currentWidget={widgets}
            setData={setData}
            setOpenForm={setOpenForm}
            refresh={refresh}
          />
        </div>
      )}

      <Drawer
        width={`60%`}
        title="Basic Drawer"
        placement="right"
        closable={true}
        onClose={onClose}
        visible={showDrawer}
      >
        <DocViewer
          pluginRenderers={DocViewerRenderers}
          documents={[{ uri: fileUrl }]}
        />
      </Drawer>
    </>
  )
}

export default WidgetData
