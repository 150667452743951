import axios from 'axios'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

class ProductService {
  getProducts = async (): Promise<any> => {
    return api
      .get('produits/all')
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        return error.response
      })
  }

  getWidgetsByProductId = async (id: string): Promise<any> => {
    return api
      .get(`/produits/${id}/widgets`)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        return error.response
      })
  }

  getProductData = async (productId: string): Promise<any> => {
    return api
      .get(`/data/${productId}`)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        return error.response
      })
  }

  getWidgetsData = async (
    productId: string,
    widgetId: string
  ): Promise<any> => {
    return api
      .get(`/data/listwidget/${productId}/${widgetId}`)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        return error.response
      })
  }
  getEtatData = async (): //productId: string,
  //widgetId: string
  Promise<any> => {
    return api
      .get(`data/`)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        return error.response
      })
  }

  getEtatDataById = async (id: string, idW: string): Promise<any> => {
    return api
      .get(`data/${id}/${idW}/listetat`)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        return error.response
      })
  }
}

const PService = new ProductService()

export default PService
