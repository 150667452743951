import React, { useState, useEffect } from 'react'
import { Drawer, Modal, Dropdown, Button } from 'antd'
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer'
import axios from 'axios'
import WidgetModal from '../widgetsComponent/WidgetModal'
import type { MenuProps } from 'antd'
import Preview from '../../../node_modules/@sidexia/visionformpreview/lib/lib/components/Preview/Preview'
interface Props {
  allData: any[]
  showArchived: boolean
  showCard?: boolean
  setCurrentParentData?: any
  showData?: any
  refresh: any
  setCurrentProductId?: any
  currentParentId?: any
}
const AllWidgets: React.FC<Props> = ({
  allData,
  showArchived,
  showCard,
  showData,
  setCurrentParentData,
  refresh,
  setCurrentProductId,
  currentParentId,
}) => {
  const [widgets, setWidgets] = useState<any>([])
  const [openForm, setOpenForm] = useState(false)
  const [modal, contextHolder] = Modal.useModal()
  const [widgetId, setWidgetId] = useState<any>()
  const [produitId, setProduitId] = useState<any>()
  const [widgetSettings, setWidgetSettings] = useState<any>()
  const [currentId, setCurrentId] = useState<any>()
  const [open, setOpen] = useState(false)
  const [data, setData] = useState<any>()
  const [showDrawer, setShowDrawer] = useState(false)
  const [fileUrl, setFileUrl] = useState('')
  const confirm = (widget: any) => {
    modal.confirm({
      title: 'Confirm',
      icon: <span></span>,
      content: "Veuillez confirmer l'archivage",
      okText: 'Confirmer',
      cancelText: 'Annuler',
      onOk: async () => {
        await archiveWidget(widget)
      },
    })
  }
  const archiveWidget = async (widget: any) => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/data/archive`,
      {
        id: widget.data_id,
        is_archived: widget.is_archived || 0,
      }
    )
    if (data.updated) {
      widgets.map((w: any, i: any) => {
        if (w.data_id === widget.data_id) {
          widgets[i].is_archived = data.nextState
        }
      })
      setWidgets(widgets)
      await refresh()
    }
  }
  const onClose = () => {
    setShowDrawer(false)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const handleOpen = (
    settings: any,
    widget_id: number,
    produit_id: number,
    data_id: any,
    data: any
  ) => {
    setWidgetSettings(
      typeof settings === 'string' ? JSON.parse(settings) : settings
    )
    setProduitId(produit_id)
    setWidgetId(widget_id)
    setOpen(true)
    setCurrentId(data_id)
    setData(data)
  }

  useEffect(() => {
    setWidgets(
      allData?.filter((item: any) => {
        item.is_archived = item.is_archived ? item.is_archived : 0
        return item.is_archived == showArchived
      })
    )
  }, [allData])

  const optionButton = (item: any) => {
    const menuItems = { items: [] as any } as MenuProps
    const dropdownElement = [
      {
        key: 0,
        label: (
          <>
            <span className="fa fa-pen me-3" />
            Edit
          </>
        ),
        onClick: () => {
          handleOpen(
            item?.settings,
            item?.widget_id,
            item?.produit_id,
            item?.data_id,
            item?.valeur
          )
        },
      },
      {
        key: 1,
        label: (
          <>
            <span className="fa fa-archive me-3" />
            Archiver
          </>
        ),
        onClick: () => {
          confirm(item)
        },
      },
    ]
    menuItems.items = dropdownElement
    return menuItems
  }
  const showCards = (item: any) => {
    item.form_preview =
      typeof item.form_preview === 'string'
        ? JSON.parse(item.form_preview)
        : item.form_preview
    if (item.form_preview) {
      return <Preview initElements={{ fields: item.form_preview }} />
    } else {
      return item.values.map((value: any, i: any) => {
        if (value.showInCard) {
          if (item.isRelated) {
            return (
              <div
                className="row cursor-pointer"
                key={i}
                role="button"
                onClick={async () => {
                  item.id = item.data_id
                  setCurrentParentData(item.id)
                  //setCurrentProductId(undefined)
                  await showData(item, item.produit_id)
                }}
              >
                <div className="col mb-3">
                  {value.label.replace(/<[^>]*>?/gm, '')} :{' '}
                </div>
                <div className="col mb-3">{value.text}</div>
              </div>
            )
          } else {
            if (value.type === 'FileUpload') {
              return (
                <div className="row" key={i}>
                  <div className="col mb-3">
                    <strong>{value.label.replace(/<[^>]*>?/gm, '')} : </strong>
                  </div>
                  <div className="col mb-3">{value.text}</div>
                  <button
                    className="ant-btn ant-btn-default"
                    onClick={() => {
                      setFileUrl(
                        `${process.env.REACT_APP_STATIC_FILES}${value.text}`
                      )
                      setShowDrawer(true)
                    }}
                  >
                    <span className="fa fa-eye" /> Preview
                  </button>
                </div>
              )
            } else {
              return (
                <div className="row" key={i}>
                  <div className="col mb-3">
                    <strong>{value.label.replace(/<[^>]*>?/gm, '')} : </strong>
                  </div>
                  <div className="col mb-3">{value.text}</div>
                </div>
              )
            }
          }
        }
      })
    }
  }
  return (
    <div style={{ width: '100%', float: 'left' }}>
      {!openForm &&
        widgets?.map((item: any, index: number) => {
          return (
            <div
              key={index}
              className={`${showCard ? 'ant-col ant-col-5 card' : ''}`}
              style={{
                margin: `${showCard ? '10px 20px' : '0 0 50px 0'}`,
                boxShadow: `${
                  item.widget_color
                    ? '-1px 0px 18px 3px ' + item.widget_color
                    : ''
                }`,
              }}
            >
              <div className="d-flex align-items-center justify-content-between mb-5">
                <h2 className="m-0">{item.nom}</h2>

                <Dropdown placement="bottomRight" menu={optionButton(item)}>
                  <button className="ant-btn ant-btn-sm ant-btn-transparent">
                    <span className="fa fa-ellipsis-vertical" />
                  </button>
                </Dropdown>
              </div>

              <div>{showCards(item)}</div>
              {item.isRelated && (
                <Button
                  className="btn btn-primary rounded-pill mx-2"
                  onClick={async () => {
                    item.id = item.data_id
                    setCurrentParentData(item.id)
                    // setCurrentProductId(undefined)
                    await showData(item, item.produit_id)
                  }}
                >
                  <span className="fa fa-arrow-right" />
                </Button>
              )}
              {item.values.map((v: any) => {
                if (v.isRelated) {
                  const settings =
                    typeof item.settings === 'string'
                      ? JSON.parse(item.settings)
                      : item.settings
                  const currentSettings = settings
                    .flat()
                    .find((s: any) => v.key === s.id)

                  if (currentParentId != v.id) {
                    return (
                      <Button
                        className="btn btn-primary rounded-pill mx-2"
                        key={currentSettings.id}
                        onClick={async () => {
                          item.id = v.id
                          item.produit_id = v.relatedTo
                          setCurrentParentData(v.id)
                          //setCurrentProductId(undefined)
                          await showData(item, v.relatedTo)
                        }}
                      >
                        <span className="fa fa-arrow-right">
                          {currentSettings.questions}
                        </span>
                      </Button>
                    )
                  }
                }
              })}
            </div>
          )
        })}

      {data && (
        <div>
          <WidgetModal
            settings={widgetSettings}
            dataId={currentId}
            prodId={produitId}
            widgetId={widgetId}
            open={open}
            handleClose={handleClose}
            isEdit={true}
            data={data}
            isDefault={false}
            setWidget={setWidgets}
            currentWidget={widgets}
            setData={setData}
            setOpenForm={setOpenForm}
            refresh={refresh}
          />
        </div>
      )}

      <Drawer
        width={`60%`}
        title="Basic Drawer"
        placement="right"
        closable={true}
        onClose={onClose}
        visible={showDrawer}
      >
        <DocViewer
          pluginRenderers={DocViewerRenderers}
          documents={[{ uri: fileUrl }]}
        />
      </Drawer>
      {contextHolder}
    </div>
  )
}

export default AllWidgets
