import { useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { fetchData, setCurrentId } from '../../store/dataSlice'
import { useSelector, useDispatch } from 'react-redux'
import ProductServices from '../../services'

function Header() {
  const history = useHistory()
  const data = useSelector((state: any) => state.data.data)
  const currentProduitId = useSelector((state: any) => state.data.currentId)
  const redirectHome = useCallback(() => history.push('/'), [history])
  const dispatch = useDispatch()

  async function fetchDatas() {
    ProductServices.getProducts().then((response) => {
      dispatch(fetchData(response))
    })
  }

  useEffect(() => {
    fetchDatas()
  }, [])

  const redirectProduit = async (id: number) => {
    history.push(`/${id}`)
    dispatch(setCurrentId(id))
  }

  return (
    <header className="header header-bottom text-center">
      <button className="ant-btn btn-brand" onClick={redirectHome}>
        VISION
      </button>

      <nav className="navbar d-none">
        {data?.map((row: any) => (
          <ul className="navbar-nav flex-row" key={row.id}>
            <li className="nav-item px-4">
              <button
                className={`btn btn-default ${
                  currentProduitId == row.id ? 'active' : ''
                }`}
                onClick={() => redirectProduit(row.id)}
              >
                {row.label}
              </button>
            </li>
          </ul>
        ))}
      </nav>
    </header>
  )
}

export default Header
