import { createSlice } from '@reduxjs/toolkit'

const widgetSlice = createSlice({
  name: 'widget',
  initialState: [],
  reducers: {
    fetchWidget: (state, action) => {
      state.length = 0
      action?.payload?.map((v: never) => {
        state.push(v)
      })
    },
  },
})

export const { fetchWidget } = widgetSlice.actions

export default widgetSlice.reducer
