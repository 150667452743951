import { configureStore } from '@reduxjs/toolkit'
import produitReducer from './produitSlice'
import widgetReducer from './widgetSlice'
import dataReducer from './dataSlice'
import addWidgetReducer from './createWidgetSlice'
const store = configureStore({
  reducer: {
    produit: produitReducer,
    widget: widgetReducer,
    data: dataReducer,
    addWidget: addWidgetReducer,
  },
})

export default store
