// import 'antd/lib/tabs/style/index.css'
import './Home.scss'
import '../../scss/main.scss'
import Main from '../../components/main/Main'

function Home() {
  return <Main />
}

export default Home
