import { createSlice } from '@reduxjs/toolkit'

const widgetSlice = createSlice({
  name: 'addWidget',
  initialState: [],
  reducers: {
    addWidget: (state, action) => {
      state = action.payload
    },
  },
})

export const { addWidget } = widgetSlice.actions
export default widgetSlice.reducer
