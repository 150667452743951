import { createSlice } from '@reduxjs/toolkit'

const dataSlice = createSlice({
  name: 'data',
  initialState: {
    data: [],
    currentId: null,
  },
  reducers: {
    fetchData: (state, action) => {
      state.data.length = 0
      action?.payload?.map((v: never) => {
        state.data.push(v)
      })
    },
    setCurrentId: (state, action) => {
      state.currentId = action.payload
    },
  },
})

export const { fetchData, setCurrentId } = dataSlice.actions

export default dataSlice.reducer
