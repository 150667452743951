import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Modal } from 'antd'
import axios from 'axios'
import { VisionForm } from '@sidexia/visionformbuilder'
import { notification, Button } from 'antd'
type NotificationType = 'success' | 'info' | 'warning' | 'error'

interface Props {
  open: boolean
  handleClose: any
  dataId: number
  currentWidgets: any
  onSave: any
  currentProductId: any
  currentParentId: any
}

const AjouteProduit: React.FC<Props> = ({
  open,
  handleClose,
  dataId,
  currentWidgets,
  onSave,
  currentProductId,
  currentParentId,
}) => {
  const history = useHistory()
  const [formData, setFormData] = useState('')
  const [widgetId, setWidgetId] = useState()
  let formRef: any
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string
  ) => {
    notification[type]({
      message: message,
      description: description,
    })
  }
  async function fetchChamps() {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/widgets/${dataId}`
    )
    const defaultWidget = response.data
    setWidgetId(defaultWidget?.id)
    defaultWidget.settings =
      typeof defaultWidget?.settings === 'string'
        ? JSON.parse(defaultWidget?.settings)
        : defaultWidget?.settings
    setFormData(defaultWidget?.settings)
  }

  useEffect(() => {
    fetchChamps()
  }, [currentWidgets])

  const submitForm = async (data: any) => {
    console.log({
      produit_id: currentProductId,
      widget_id: widgetId,
      valeur: [data],
      isDefault: true,
    })
    await axios.post(`${process.env.REACT_APP_API_URL}/data`, {
      produit_id: currentProductId,
      widget_id: widgetId,
      data_id: currentParentId,
      valeur: [data],
      isDefault: currentParentId !== null ? false : true,
    })
    onSave()
    handleClose()
  }

  const saveFormRef = (formRefData: any) => {
    formRef = formRefData
  }
  const handleCustomSubmit = () => {
    const form = formRef.props.form
    form.validateFields((err: any, values: any) => {
      if (!err) {
        submitForm(values)
        form.resetFields()
      } else {
        openNotificationWithIcon(
          'error',
          'Champs obligatoire',
          'Champs obligatoire'
        )
      }
    })
  }

  const showForm = () => {
    if (formData && formData?.length > 0) {
      return (
        <>
          <VisionForm
            wrappedComponentRef={saveFormRef}
            submitButton={false}
            data={formData}
          />
        </>
      )
    } else {
      return <></>
    }
  }

  return (
    <>
      <Modal
        open={open}
        onCancel={handleClose}
        closable={false}
        centered
        title={currentWidgets.name}
        footer={
          <Button type="default" htmlType="submit" onClick={handleCustomSubmit}>
            Sauvgarder
          </Button>
        }
        destroyOnClose
      >
        {showForm()}
      </Modal>
    </>
  )
}

export default AjouteProduit
