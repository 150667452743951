import React, { useEffect, useState } from 'react'
import { Table, Col } from 'antd'
import ProductServices from '../../services'

interface Props {
  produitId: any
  currentParentId: any
}

const Etat: React.FC<Props> = ({ produitId, currentParentId }) => {
  const [data, setData] = useState<any>(null)
  useEffect(() => {
    const idW = currentParentId
    const id = produitId
    fetchData(id, idW)
  }, [produitId])

  const fetchData = (id: any, idW: any) => {
    ProductServices.getEtatDataById(id, idW)
      .then((response) => {
        setData(response)
      })
      .catch((error) => {
        console.log(
          "Une erreur s'est produite lors de la récupération des données :",
          error
        )
      })
  }
  const getData = () => {
    const etatsContent = data.map((d: any, index: any) => {
      return (
        <div key={index}>
          <h4>{d.nom}</h4>
          <div>
            <div key={index}>
              <Table
                dataSource={d.tables.table}
                columns={d.tables.header}
                footer={() => {
                  return (
                    <div
                      style={{
                        width: '100%',
                        padding: '10px',
                        minHeight: '35px',
                      }}
                    >
                      <div style={{ float: 'left' }}>
                        <strong>Total: </strong>
                      </div>
                      <div style={{ float: 'right' }}>
                        <strong>
                          {d.tables.totals} {d.tables.suffix}
                        </strong>
                      </div>
                    </div>
                  )
                }}
              />
            </div>
            <Col span={24} md={12} lg={8}></Col>
          </div>
        </div>
      )
    })
    return etatsContent
  }
  return <div>{data ? getData() : <p>Chargement des données...</p>}</div>
}

export default Etat
