import React from 'react'
import { VisionForm } from '@sidexia/visionformbuilder'
import axios from 'axios'
import { notification, Button, Modal } from 'antd'
import moment from 'moment'

type NotificationType = 'success' | 'info' | 'warning' | 'error'

interface Props {
  open: boolean
  handleClose: any
  settings: any
  dataId: number
  prodId: number
  widgetId: number
  isEdit: boolean
  data: any
  isDefault: boolean
  setWidget: any
  currentWidget: any
  setData: any
  setOpenForm: any
  refresh?: any
}
let formRef: any
const WidgetModal: React.FC<Props> = ({
  handleClose,
  open,
  settings,
  dataId,
  prodId,
  widgetId,
  isEdit,
  data,
  isDefault,
  setWidget,
  currentWidget,
  setData,
  setOpenForm,
  refresh,
}) => {
  const jsonData = typeof data === 'string' ? JSON.parse(data) : data
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string
  ) => {
    notification[type]({
      message: message,
      description: description,
    })
  }

  const saveFormRef = (formRefData: any) => {
    formRef = formRefData
  }
  const submitForm = async (data: any) => {
    if (!isEdit) {
      await axios.post(`${process.env.REACT_APP_API_URL}/data`, {
        produit_id: prodId,
        widget_id: widgetId,
        data_id: isDefault ? undefined : dataId,
        valeur: [data],
        isDefault: isDefault || false,
      })
    } else {
      await axios.post(`${process.env.REACT_APP_API_URL}/data/update`, {
        dataId: dataId,
        id: dataId,
        valeur: [data],
      })
    }
    currentWidget[0].form_preview = setNewData(
      data,
      currentWidget[0].form_preview,
      true
    )
    currentWidget[0].values = setNewData(data, currentWidget[0].values, false)
    currentWidget[0].valeur = data
    setWidget(currentWidget)
    setData(data)
    await refresh()
  }
  const setNewData = (data: any, newData: any, setText: any) => {
    newData.map((d: any, i: any) => {
      if (d.fields) {
        newData[i].fields = setNewData(data, d.fields, true)
      } else {
        const currentSettings = settings
          ?.flat()
          ?.find((s: any) => s.id === d.key)
        const arrayOptions = ['Select', 'Tags', 'RadioButtons', 'Checkboxes']
        if (
          currentSettings &&
          arrayOptions.indexOf(currentSettings.element) !== -1
        ) {
          const currentOptions = currentSettings.options.find(
            (o: any) => o.value == data[d.key]
          )
          newData[i].value = !setText ? data[d.key] : currentOptions.text[0]
          newData[i].text = currentOptions.text[0]
        } else {
          newData[i].value =
            typeof data[d.key] !== 'object'
              ? data[d.key]
              : data[d.key]?.format(process.env.REACT_APP_FORMAT_DATE)
          newData[i].text = data[d.key]
        }
      }
    })
    return newData
  }
  const checkCondition = (values: any, s: any) => {
    if (s.conditionDate && s.conditionDate.inputType) {
      const currentInput = values[s.id]
      const compareInput =
        s.conditionDate.fieldId === 'now'
          ? moment()
          : values[s.conditionDate.fieldId]
      const currentCondition = s.conditionDate.operation

      switch (currentCondition) {
        case 'bigger':
          return moment(currentInput).isAfter(compareInput)
        case 'less':
          return moment(currentInput).isBefore(compareInput)
        default:
          return true
      }
    }
    return true
  }
  const handleCustomSubmit = () => {
    const form = formRef.props.form
    const conditions: any = []
    form.validateFields((err: any, values: any) => {
      settings?.flat()?.map((s: any) => {
        if (!checkCondition(values, s)) {
          openNotificationWithIcon(
            'error',
            `${s.questions} n'est pas correct`,
            `${s.questions} n'est pas correct`
          )
          err = true
        }
      })
      if (!err) {
        submitForm(values)
        form.resetFields()
        handleClose()
        document.body.classList.remove('show-preview')
      } else {
        openNotificationWithIcon(
          'error',
          'Erreurs dans le formulaire',
          'Erreurs dans le formulaire'
        )
      }
    })
  }
  const getForm = (settings: any, jsonData: any) => {
    const currentData = Array.isArray(jsonData) ? jsonData[0] : jsonData
    Object.keys(currentData).map((d: any) => {
      currentData[d] =
        typeof currentData[d] !== 'object'
          ? currentData[d]
          : currentData[d]?.format
          ? currentData[d]?.format(process.env.REACT_APP_FORMAT_DATE)
          : currentData[d]
    })
    currentData.fullData = currentWidget.find(
      (w: any) => w.widget_id === widgetId
    )?.values
    if (settings) {
      return (
        <VisionForm
          wrappedComponentRef={saveFormRef}
          submitButton={false}
          data={settings}
          filledData={currentData}
        />
      )
    } else {
      return <></>
    }
  }
  return (
    <Modal
      open={open}
      onCancel={() => {
        handleClose()
        document.body.classList.remove('show-preview')
      }}
      closable={false}
      footer={null}
      destroyOnClose
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <div title="modal"></div>
      {getForm(settings, jsonData)}
      <Button type="default" htmlType="submit" onClick={handleCustomSubmit}>
        Sauvgarder
      </Button>
    </Modal>
  )
}
export default WidgetModal
