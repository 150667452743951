import React, { useState, useEffect } from 'react'
import axios from 'axios'
import WidgetModal from '../widgetsComponent/WidgetModal'
import AllWidgets from './AllWidgets'
import { Dropdown } from 'antd'
import type { MenuProps } from 'antd'

interface Props {
  id: number
}
const Widget: React.FC<Props> = ({ id }) => {
  const [open, setOpen] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [currentId, setCurrentId] = useState(id)
  const [dataWidgets, setDataWidgets] = useState({
    data: [] as any[],
    default: {
      results: [] as any[],
      settings: [] as any[],
      valeur: [] as any,
      produit_id: Number as any,
      data_id: Number as any,
      widget_id: Number as any,
      values: [] as any,
    },
    widgets: [] as any[],
  })
  const [widgetId, setWidgetId] = useState<any>()
  const [isDefault, setIsDefault] = useState<any>()
  const [produitId, setProduitId] = useState<any>()
  const [widgetSettings, setWidgetSettings] = useState<any>()
  const [data, setData] = useState<any>()
  const [showArchived, setShowArchive] = useState(false)
  const [menu, setMenu] = useState<MenuProps>([] as MenuProps)
  const [isdisabled, setIsdisabled] = useState(false)
  const handleOpen = (
    settings: any,
    widget_id: number,
    produit_id: number,
    data_id: any,
    data: any,
    isDefault: boolean
  ) => {
    setWidgetSettings(settings)
    setProduitId(produit_id)
    setWidgetId(widget_id)
    setOpen(true)
    setCurrentId(data_id)
    setData(data)
    setIsDefault(isDefault)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const handleGoBack = () => {
    window.history.back()
  }
  const getData = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/data/data/${id}`
    )
    const settings = response.data.default.settings
    const valuer = response.data.default.valeur
    const valeus = response.data.default.valeus
    setDataWidgets({
      data: response.data.data,
      default: {
        results: response.data.default.results,
        settings:
          typeof settings === 'string' ? JSON.parse(settings) : settings,
        valeur: typeof valuer === 'string' ? JSON.parse(valuer) : valuer,
        widget_id: response.data.default.widget_id,
        data_id: response.data.default.data_id,
        produit_id: response.data.default.id_produit,
        values: typeof valeus === 'string' ? JSON.parse(valeus) : valeus,
      },
      widgets: response.data.widgets,
    })
  }

  useEffect(() => {
    const menuItems = { items: [] as any } as MenuProps
    const addedItems = getDataWidgetsByParams()
    setIsdisabled(addedItems.length === 0)
    addedItems?.map((item: any, index: number) => {
      menuItems?.items?.push({
        key: index,
        label: item.nom,
        onClick: () => {
          handleOpen(
            item.settings,
            item.widget_id,
            item.produit_id,
            id,
            null,
            item.isChild
          )
        },
      })
    })
    setMenu(menuItems)
  }, [dataWidgets])

  useEffect(() => {
    getData()
  }, [])

  const getDataWidgetsByParams = (isChild = false) => {
    let filteredResult = dataWidgets.widgets.filter((w: any) => !w.default)
    if (isChild) {
      filteredResult = filteredResult.filter((w: any) => w.isChild)
    } else {
      filteredResult = filteredResult.filter((w: any) => !w.isChild)
    }
    return filteredResult
  }

  const DropdownButton = (
    <button className="ant-btn ant-btn-default mx-2" disabled={isdisabled}>
      <span className="fa fa-add me-2" /> Widget
    </button>
  )

  return (
    <main className="container py-5">
      {/* lhm */}
      <div className="d-none hpx-125 d-flex align-items-center justify-content-center my-5 p-5 text-white rounded-default bg-linear-1">
        {dataWidgets.default?.results?.map((item: any, index: number) => {
          return (
            <div key={index}>
              {item.isRelated && (
                <h1
                  onClick={() => {
                    window.location.replace(
                      `/widgets/${dataWidgets.default.valeur[0].relatedTo}`
                    )
                  }}
                >
                  {item.text}
                </h1>
              )}
              {!item.isRelated && <h1>{item.text}</h1>}
            </div>
          )
        })}
      </div>

      <div className="d-flex justify-content-between my-5">
        <button className="ant-btn ant-btn-default" onClick={handleGoBack}>
          <span className="fa fa-chevron-left" />
        </button>

        <div>
          <button
            className="ant-btn ant-btn-default"
            onClick={() => {
              setShowArchive(!showArchived)
            }}
          >
            <span className="fa fa-archive" />
          </button>

          {isdisabled ? (
            DropdownButton
          ) : (
            <Dropdown placement="bottomRight" menu={menu}>
              {DropdownButton}
            </Dropdown>
          )}

          <button
            className="ant-btn ant-btn-default"
            onClick={() => {
              setIsEdit(true)
              handleOpen(
                dataWidgets.default?.settings,
                dataWidgets.default?.widget_id,
                dataWidgets.default?.produit_id,
                dataWidgets.default?.data_id,
                dataWidgets.default?.valeur,
                false
              )
            }}
          >
            <span className="fa fa-pen me-2" /> Edit
          </button>
        </div>
      </div>

      <AllWidgets
        allData={dataWidgets.data}
        showArchived={showArchived}
        refresh={null}
      />

      {
        <WidgetModal
          settings={widgetSettings}
          dataId={currentId}
          prodId={produitId}
          widgetId={widgetId}
          open={open}
          handleClose={handleClose}
          isEdit={isEdit}
          data={data}
          isDefault={isDefault}
          setWidget={null}
          currentWidget={null}
          setData={null}
          setOpenForm={null}
        />
      }
    </main>
  )
}

export default Widget
