import { BrowserRouter, Route, Switch } from 'react-router-dom'
import routesPath from '../values/routesPath.ts'
import PageNotFound from '../pages/404/index.ts'
import Home from '../pages/Home/index.ts'
import ProduitsList from '../pages/Produits/index.ts'
import WidgetsList from '../pages/Widgets/index.ts'
import Header from '../components/header/Header.tsx'
const AppNavigation = () => {
  return (
    <BrowserRouter>
      <Header />
      <Switch>
        <Route path={routesPath.Home.list} exact component={Home} />
        <Route path={routesPath.Widgets.list} component={WidgetsList} />
        <Route path={routesPath.Produits.list} component={ProduitsList} />
        <Route path="*" component={PageNotFound} />
      </Switch>
    </BrowserRouter>
  )
}

export default AppNavigation
