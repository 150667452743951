import { useCallback, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Produit from '../../components/produitsComponent/Produit'
import AjouteProduit from './AjouteProduit'
import { useSelector, useDispatch } from 'react-redux'
import { setCurrentId } from '../../store/dataSlice'
import ProductServices from '../../services'
import { Breadcrumb, Card } from 'antd'

const ProduitsList = ({ match }: any) => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [widgets, setWidgets] = useState<any[]>([])
  const [currentWidgets, setCurrentWidgets] = useState<any>({})
  const [showListId, setShowListId] = useState<any>(match.params.id)
  const [dataList, setDataList] = useState<any[]>([])
  const [isProductList, setIsProductList] = useState(true)
  const history = useHistory()
  const redirectHome = useCallback(() => history.push('/'), [history])

  const currentProduit = useSelector(
    (state: any) =>
      state.data.data.find((x: any) => x.id == match.params.id) || {}
  )

  useEffect(() => {
    dispatch(setCurrentId(match.params.id))
  }, [])

  useEffect(() => {
    resetPage()
    fetchWidgets()
  }, [match.params.id])

  useEffect(() => {
    fetchData()
  }, [showListId])

  async function fetchWidgets() {
    ProductServices.getWidgetsByProductId(match.params.id).then((response) => {
      setWidgets(response)
      setCurrentWidgets(response.find((w: any) => w.default))
    })
  }

  async function fetchData() {
    if (isProductList) {
      ProductServices.getProductData(match.params.id).then((response) => {
        setDataList(response)
      })
    } else {
      ProductServices.getWidgetsData(match.params.id, showListId).then(
        (response) => {
          setDataList(response)
        }
      )
    }
  }

  const onWidgetClick = (selectedWidget: any) => {
    setIsProductList(false)
    setShowListId(selectedWidget.id)
    setCurrentWidgets(selectedWidget)
  }

  const onProductClick = () => {
    resetPage()
  }

  const resetPage = () => {
    setIsProductList(true)
    setCurrentWidgets({})
    setShowListId(match.params.id)
  }

  const handleOpen = () => {
    if (currentWidgets.id) {
      setOpen(true)
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <main className="container">
      <h1>{currentProduit.label}</h1>

      <Breadcrumb
        className={'ant-breadcrumb-wrap mb-5'}
        items={[
          {
            title: <span className="fa fa-home" />,
            href: '',
            onClick: redirectHome,
          },
          {
            title: currentProduit.label,
            href: '',
            onClick: onProductClick,
          },
          { title: currentWidgets.nom },
        ]}
      />

      <div className="text-center">
        {widgets.map((x: any, index: any) => {
          return (
            <button
              key={index}
              className="ant-btn ant-btn-filter mx-2"
              onClick={() => onWidgetClick(x)}
            >
              {x.nom}
            </button>
          )
        })}
      </div>

      <div className="d-flex justify-content-between mt-5">
        <h2 className="m-0">Liste {currentProduit.label}</h2>

        <button
          className="ant-btn ant-btn-default"
          disabled={!currentWidgets?.id}
          onClick={handleOpen}
        >
          <span className="fa fa-plus me-2" /> Ajouter
        </button>

        <AjouteProduit
          currentWidgets={currentWidgets}
          dataId={match.params.id}
          open={open}
          handleClose={handleClose}
          onSave={null}
          currentProductId={null}
          currentParentId={null}
        />
      </div>

      <Card bordered={false} className="mt-3">
        <Produit dataList={dataList} />
      </Card>
    </main>
  )
}

export default ProduitsList
