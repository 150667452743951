import React, { useEffect, useState } from 'react'
import { Menu, Layout, theme, Table, Space, Button, Dropdown } from 'antd'
import type { MenuProps } from 'antd'
import axios from 'axios'
import AjouteProduit from '../../pages/Produits/AjouteProduit'
import AllWidgets from '../widgetsComponent/AllWidgets'
import WidgetData from '../widgetsComponent/WidgetData'
import Etat from '../EtatsComponent/Etat'

type MenuItem = Required<MenuProps>['items'][number]
const { Header, Content, Footer, Sider } = Layout

function Main() {
  const [menuItems, setMenuItems] = useState<MenuProps['items']>([])
  const [currentProductId, setCurrentProductId] = useState(null)
  const [currentParentData, setCurrentParentData] = useState(null)
  const [currentWidgets, setCurrentWidgets] = useState<any>(null)
  const [showCard, setShowCard] = useState(false)
  const [isDefault, setIsDefault] = useState(true)
  const [open, setOpen] = useState(false)
  const [products, setProducts] = useState({ products: [], relatedProduct: [] })
  const [columns, setColumns] = useState([])
  const [dataSource, setDataSource] = useState([])
  const [buttonMenu, setButtonMenu] = useState<MenuProps>([] as MenuProps)
  const [currentProfile, setCurrentProfile] = useState<any>([])
  const [restData, setRestData] = useState<any>([])
  const [archivedData, setArchivedData] = useState<any>([])
  const [refreshData, setRefreshData] = useState<any>({})
  const handleClose = () => {
    setOpen(false)
  }
  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group'
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      type,
    } as MenuItem
  }

  const getSubWidgets = (widgets: any) => {
    const filteredWidgets = widgets.filter((w: any) => w.default == isDefault)
    return filteredWidgets.map((w: any) => getItem(w.name, w.id))
  }
  const getAllProduct = async (id: any) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/produits/all${
        id ? '?parent_id=' + id : ''
      }`
    )
    setProducts(data)
  }
  const refresh = async () => {
    setCurrentParentData(refreshData.id)
    await showData(refreshData.record, refreshData.prodcutId)
  }
  const getDataById = async (product: any) => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/data/byId`,
      {
        produit_id: product.id,
        widget_id: product.widget.id,
        current_id: !isDefault ? currentParentData : null,
      }
    )
    setCurrentProductId(data.produit_id)
    if (data.header.length > 0) {
      data.header.push({
        title: '',
        render: (_: any, record: any) => (
          <Space size="middle">
            <Button
              className="btn btn-primary rounded-pill mx-2"
              onClick={async () => {
                setCurrentParentData(record.id)
                await showData(record, data.produit_id)
                setRefreshData({
                  id: record.id,
                  record: record,
                  prodcutId: data.produit_id,
                })
              }}
            >
              <span className="fa fa-arrow-right" />
            </Button>
          </Space>
        ),
        key: 'action',
      })
    }
    setDataSource(data.table.length > 0 ? data.table : [])
    setColumns(data.header.length > 0 ? data.header : [])
  }
  useEffect(() => {
    const items: MenuProps['items'] = []
    const menuItemsWidgets = { items: [] as any } as MenuProps
    console.log(products)
    products.products?.map((d: any) => {
      d.widgets?.map((item: any) => {
        if (!item.default) {
          menuItemsWidgets?.items?.push({
            key: item.id,
            label: item.name,
            onClick: () => {
              setCurrentWidgets({ ...{ widget: item }, id: currentProductId })
              handleOpen()
            },
          })
        }
      })
      items?.push(
        getItem(
          d.name,
          d.id,
          <span className="fa fa-file" />,
          getSubWidgets(d.widgets)
        )
      )
    })
    products.relatedProduct.map((d: any) => {
      d.widgets?.map((item: any) => {
        if (item.default == 1) {
          menuItemsWidgets?.items?.push({
            key: item.id,
            label: item.name,
            onClick: () => {
              setCurrentWidgets({ ...{ widget: item }, id: currentProductId })
              handleOpen()
            },
          })
        }
      })
    })
    console.log(items)
    setButtonMenu(menuItemsWidgets)
    setMenuItems(items)
  }, [products])

  useEffect(() => {
    getAllProduct(null)
  }, [])
  const getProductData = async (id: any) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/data/data/${id}`
    )
    setRestData(data.data.filter((d: any) => d.data_id !== id))
    const currentProfileData = data.data.filter((d: any) => d.data_id === id)
    currentProfileData[0].is_archived = 0
    setCurrentProfile(currentProfileData)
    Object.keys(data.tables).map((key: any) => {
      if (data.tables[key].header.length > 0) {
        data.tables[key].header.push({
          title: '',
          render: (_: any, record: any) => {
            const currentWidgetData = data.data.find((d: any) => {
              return d.id === record.id || d.data_id === record.id
            })
            return (
              <WidgetData
                item={currentWidgetData}
                index={currentWidgetData.id}
                showCard={showCard}
                setCurrentParentData={setCurrentParentData}
                showData={showData}
                refresh={refresh}
              />
            )
          },
          key: 'show',
        })
      }
    })
    setArchivedData(data.tables)
    setShowCard(true)
  }
  const getPByWId = (id: any) => {
    let currentProduct: any = {}
    products.products.map((product: any) => {
      if (product.widgets.some((item: any) => item.id == id)) {
        currentProduct = {
          ...product,
          widget: product.widgets.find((item: any) => item.id == id),
        }
      }
    })
    getDataById(currentProduct)
    setCurrentWidgets(currentProduct)
  }
  const onClick: MenuProps['onClick'] = async (e) => {
    setShowCard(false)
    getPByWId(e.key)
  }
  const showData = async (data: any, currentProductId: any) => {
    setIsDefault(false)
    setCurrentProductId(currentProductId)
    await getAllProduct(currentProductId)
    await getProductData(data.id)
  }
  const handleOpen = () => {
    setOpen(true)
  }

  const {
    token: { colorBgContainer },
  } = theme.useToken()
  return (
    <Layout>
      <Header style={{ display: 'flex', alignItems: 'center' }}>
        <div className="demo-logo" />
      </Header>
      <Content style={{ padding: '0 50px' }}>
        <Layout
          style={{
            padding: '24px 0',
            background: colorBgContainer,
            minHeight: '100vh',
          }}
        >
          <Sider
            style={{ background: colorBgContainer, overflow: 'hidden' }}
            width={300}
          >
            <Menu
              onClick={onClick}
              style={{ height: '100%' }}
              defaultSelectedKeys={['1']}
              defaultOpenKeys={['sub1']}
              mode="inline"
              items={menuItems}
            />
          </Sider>
          <Content style={{ padding: '0 24px', minHeight: 280 }}>
            <Button
              style={{ float: 'left', marginBottom: '20px' }}
              disabled={isDefault}
              className="btn btn-primary rounded-pill mx-2"
              onClick={() => {
                setIsDefault(true)
                setShowCard(false)
                getAllProduct(null)
              }}
            >
              <span className="fa fa-arrow-left" />
            </Button>
            <button
              className="ant-btn ant-btn-default"
              style={{ float: 'right', marginBottom: '20px' }}
              disabled={!currentWidgets?.id}
              onClick={() => {
                setCurrentParentData(null)
                handleOpen()
              }}
            >
              <span className="fa fa-plus me-2" /> Ajouter
            </button>

            {isDefault ? (
              <button
                className="ant-btn ant-btn-default mx-2"
                disabled={true}
                style={{ float: 'right', marginBottom: '20px' }}
              >
                <span className="fa fa-add me-2" /> Widget
              </button>
            ) : (
              <Dropdown placement="bottomRight" menu={buttonMenu}>
                <button
                  className="ant-btn ant-btn-default mx-2"
                  disabled={buttonMenu.items !== undefined}
                  style={{ float: 'right', marginBottom: '20px' }}
                >
                  <span className="fa fa-add me-2" /> Widget
                </button>
              </Dropdown>
            )}
            {!showCard && <Table dataSource={dataSource} columns={columns} />}
            {showCard && (
              <>
                {/*<AllWidgets
                  allData={currentProfile}
                  showArchived={false}
                  refresh={refresh}
                  setCurrentParentData={setCurrentParentData}
                  setCurrentProductId={setCurrentProductId}
                  showData={showData}
            />*/}
                <AllWidgets
                  showCard={true}
                  allData={[...currentProfile, ...restData]}
                  showArchived={false}
                  setCurrentParentData={setCurrentParentData}
                  setCurrentProductId={setCurrentProductId}
                  showData={showData}
                  refresh={refresh}
                  currentParentId={currentParentData}
                />
                <div style={{ width: '100%', float: 'left' }}>
                  {currentProductId !== undefined && (
                    <Etat
                      produitId={currentProductId}
                      currentParentId={currentParentData}
                    />
                  )}
                </div>
                <div style={{ width: '100%', float: 'left' }}>
                  {Object.keys(archivedData).length > 0 && <h3>Archived</h3>}
                  {Object.keys(archivedData).map((index: any) => (
                    <div key={index}>
                      <h4>{decodeURIComponent(index)}</h4>
                      <div>
                        <Table
                          dataSource={archivedData[index].table}
                          columns={archivedData[index].header}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
            {currentWidgets && (
              <AjouteProduit
                currentWidgets={currentWidgets?.widget}
                dataId={currentWidgets?.widget?.id}
                currentProductId={currentProductId}
                currentParentId={currentParentData}
                open={open}
                handleClose={handleClose}
                onSave={() => {
                  getDataById(currentWidgets)
                  refresh()
                }}
              />
            )}
          </Content>
        </Layout>
      </Content>
      <Footer style={{ textAlign: 'center' }}>Sidexia ©2023</Footer>
    </Layout>
  )
}

export default Main
