import React from 'react'
import { Table } from 'antd'
import { useHistory } from 'react-router-dom'

interface Props {
  dataList: any[]
}

const Produit: React.FC<Props> = ({ dataList }) => {
  console.log(dataList)

  const history = useHistory()

  const redirectWidget = async (id: number) => {
    history.push(`/widgets/${id}`)
  }

  const columns = [
    {
      key: 'id',
      title: 'Id',
      dataIndex: 'id',
    },
    {
      key: 'text',
      title: 'Texte',
      dataIndex: 'text',
    },
  ]

  return (
    <Table
      className="ant-table-cursor"
      dataSource={dataList}
      columns={columns}
      showHeader={false}
      pagination={false}
      onRow={(prod) => ({
        onClick: () => redirectWidget(prod.id),
      })}
    />
  )
}

export default Produit
