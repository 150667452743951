import { createSlice } from '@reduxjs/toolkit'

const produitSlice = createSlice({
  name: 'produit',
  initialState: [],
  reducers: {
    fetchProduit: (state, action) => {
      state.length = 0
      action?.payload?.map((v: never) => {
        state.push(v)
      })
    },
    addProduit: (state, action) => {
      state = action.payload
    },
  },
})

export const { fetchProduit, addProduit } = produitSlice.actions
export default produitSlice.reducer
