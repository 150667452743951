import Widget from '../../components/widgetsComponent/widget'
const WidgetsList = ({ match }: any) => {
  return (
    <div>
      <Widget id={match.params.id} />
    </div>
  )
}

export default WidgetsList
